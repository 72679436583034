<template>
  <!-- 产品中心 -->
  <div class="product">
    <div class="img_1">
      <img src="../../../assets/img/newschool/Pic_XXFW_JX.png" alt="" />
    </div>
    <div class="img_2">
      <img src="../../../assets/img/newschool/Pic_XXFW_DD.png" alt="" />
    </div>
    <div class="img_3">
      <img src="../../../assets/img/newschool/Pic_XXFW_ZC.png" alt="" />
    </div>
    <div class="img_4">
      <img src="../../../assets/img/newschool/Pic_XXFW_XCX.png" alt="" />
    </div>
  </div>
  <!-- 校园中心 申报中心HTML代码 如需开始吧注释去掉  -->
  <!-- <div class="declare">
    <div class="declare-rou">
      <ul>
        <li
          v-for="(item, index) in menu"
          :key="index"
          :class="{ active: $route.path == item.path ? true : false }"
        >
          <a @click="goJump(item)">{{ item.name }}</a>
        </li>
      </ul>
    </div>
    <div class="declare-content">
      <router-view></router-view>
      
    </div>
  </div> -->
</template>
<script>
// <component :is="currentComp"></component>
// import demonstrationDeclare from "./demonstrationDeclare.vue";
// import zuteDeclare from "./zuteDeclare.vue";
export default {
  data() {
    return {
      menu: [
        {
          name: "示范园申报",
          value: "1",
          comp: "demonstrationDeclare",
          path: "/admin/declare/demonstrationDeclare",
        },
        {
          name: "足特园申报",
          value: "2",
          comp: "zuteDeclare",
          path: "/admin/declare/zuteDeclare",
        },
      ],
    };
  },
  methods: {
    goJump(data) {
      if (data.value == "2") {
        this.$notice({
          message:
            "2021年全国足球特色幼儿园申报已截止。如有疑问，请拨打400-8319-799或咨询当地教育主管部门。",
        }).isShow();
        //  this.$router.push({
        //     path: data.path,
        // });
      } else {
        this.$router.push({
          path: data.path,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.declare {
  // white-space: nowrap;
  // display: inline-block;
  width: 1434px;
  margin: 0 auto;
}
.declare-rou {
  background: #ffffff;
  height: 58px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  > ul {
    width: 1434px;
    margin: 0 auto;
    padding-top: 16px;

    li {
      margin: 0 65px;
      display: inline-block;
      width: 122px;
      font-size: 24px;
      line-height: 38px;
      font-weight: normal;
      color: #333333;
      text-align: center;
      cursor: pointer;
    }
    .active {
      color: #ba3037;
      border-bottom: 4px solid #ba3037;
      font-weight: bold;
      a {
        color: #ba3037;
      }
    }
  }
}
.declare-content {
  // margin-top: 40px;
}
.product {
  img {
    width: 100%;
  }
}
</style>